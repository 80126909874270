import React from 'react';

export function trackEvent(event, options = {}) {
  if (process.env.NODE_ENV === 'development') console.log('trackEvent', ...arguments);
  if (global.gtag) global.gtag('event', event, options);
}
export function trackPageView(googleAnalytics, page_path) {
  if (process.env.NODE_ENV === 'development') console.log('trackPageView', ...arguments);
  if (global.gtag) global.gtag('config', googleAnalytics, {page_path});
}

export const TrackPageView = React.memo(({pathname, googleAnalytics}) => {
  trackPageView(googleAnalytics, pathname);
  return null;
});
