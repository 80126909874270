import React from 'react';
import {Root, Routes, withSiteData} from 'react-static';
import {Link} from '@reach/router';
import styles from './styles.scss';

export default withSiteData(
  class Header extends React.Component {
    render() {
      return (
        <header className={styles.root} key="main">
          <div className={styles.main}>
            <h1 className={styles.logo}>
              <Link to="/">{this.props.title}</Link>
            </h1>
            <nav className={styles.nav}>
              <Link to="/about">About</Link>
              <Link to="/pricing">Pricing</Link>
              <Link to="/contact">Contact</Link>
            </nav>
          </div>
          {this.renderAlbums()}
        </header>
      );
    }

    renderAlbums() {
      if (!this.props.showAlbums) return;

      return (
        <nav className={styles.albums} key="albums">
          <Link to="/">Recent</Link>
          {this.props.albumsMenu.map(this.renderAlbum)}
        </nav>
      );
    }

    renderAlbum = (album, index) => {
      return (
        <Link to={`/albums/${album.slug}`} key={index}>
          {album.title}
        </Link>
      );
    };
  }
);
