
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.js'), universalOptions)
const t_1 = universal(import('../src/pages/admin.js'), universalOptions)
const t_2 = universal(import('../src/pages/index.js'), universalOptions)
const t_3 = universal(import('../src/containers/Album'), universalOptions)
const t_4 = universal(import('../src/containers/About'), universalOptions)
const t_5 = universal(import('../src/containers/Pricing'), universalOptions)
const t_6 = universal(import('../src/containers/Contact'), universalOptions)


// Template Map
export default {
  '../src/pages/404.js': t_0,
'../src/pages/admin.js': t_1,
'../src/pages/index.js': t_2,
'../src/containers/Album': t_3,
'../src/containers/About': t_4,
'../src/containers/Pricing': t_5,
'../src/containers/Contact': t_6
}

export const notFoundTemplate = "../src/pages/404.js"
