import React from 'react';
import {Root, Head, Routes, withSiteData} from 'react-static';
import {Location} from '@reach/router';
import Header from './components/Header';
import {TrackPageView} from './lib/track';
import styles from './styles.scss';

export default withSiteData(function App(props) {
  return (
    <Root>
      <Head defaultTitle={props.title} titleTemplate={`%s – ${props.title}`}>
        <meta charSet="utf-8" />
      </Head>
      <Header showAlbums />
      <main className={styles.root}>
        <Routes />
      </main>
      <footer className={styles.footer}>
        <SocialLinks socialLinks={props.socialLinks} />
        <div className={styles.copyright}>
          &copy; {new Date().getFullYear()} {props.author || props.title}
        </div>
      </footer>
      <Location>
        {({location}) => <TrackPageView pathname={location.pathname} googleAnalytics={props.googleAnalytics} />}
      </Location>
    </Root>
  );
});

function SocialLinks({socialLinks}) {
  if (socialLinks == null || socialLinks.length === 0) return null;

  return (
    <nav className={styles.socialLinks}>
      {socialLinks.map((link, index) => (
        <a href={link.url} title={link.name} target="_blank" rel="noopener noreferrer" key={index}>
          {link.name}
        </a>
      ))}
    </nav>
  );
}
